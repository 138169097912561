import { getAuth } from 'firebase/auth';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

function Home() {
  const [user, setUser] = useState(null);
  const auth = getAuth();

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged((user) => {
  //     if (user) {
  //       setUser(user);
  //     } else {
  //       setUser(null);
  //     }
  //   });

  //   return () => unsubscribe();
  // }, [auth]);

  if (user === null) {
    return <Navigate to="/login" />;
  }

  return <h2>Dobrodošli na Home stranicu, {user.email}!</h2>;
}

export default Home;
