import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Importuj getAuth
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBUNoYzeEFeeHt5DU1um-k9FeBAONoQv7I",
  authDomain: "react-56907.firebaseapp.com",
  projectId: "react-56907",
  storageBucket: "react-56907.appspot.com",
  messagingSenderId: "185026318053",
  appId: "1:185026318053:web:3fd015f8d7496d39b4ce0c",
  measurementId: "G-Y3RG1DB2YZ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app); // Inicijalizuj Authentication

export { auth, db }; // Exportuj auth
