import { signInWithEmailAndPassword } from 'firebase/auth';

import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { auth } from '../firebaseConfig'; // Importuj auth




function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');


  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Prijavljen korisnik
        const user = userCredential.user;
        console.log('Korisnik prijavljen: ', user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error('Greška u prijavljivanju: ', errorCode, errorMessage);
      });
  };



  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}


        <Form>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Unesite email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label>Lozinka</Form.Label>
            <Form.Control
              type="password"
              placeholder="Unesite lozinku"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          {/* Dugme za prijavu */}
          <Button variant="primary" onClick={handleLogin}>
            Prijavi se
          </Button>
        </Form>
   
      </header>
    </div>
  );
}

export default Login;
